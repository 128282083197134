<template>
  <div class="team-table">
    <!-- table header -->
    <div class="table-header">
      <div></div>
      <div>{{ $t("category") }}</div>
      <div>{{ $t("by_gender") }}</div>
      <div class="selected-column">{{ $t("time") }}</div>
    </div>

    <!-- table content -->
    <!-- top list -->
    <template v-for="member in resultLeaderboard.listTop">
      <team-result-item
        :key="member.rank"
        :result="member"
        :screen="screenWidth"
      />
    </template>

    <!-- load hidden -->
    <template v-if="showHiddenMembersRow">
      <div class="hidden-members" @click="showHiddenMembers">
        <div class="more-icon">
          <i class="material-icons-outlined"> more_horiz </i>
        </div>
        <div class="members-count">
          {{ hiddenMembersCount + " " + $t("hidden_members") }}
        </div>
      </div>
    </template>

    <!-- bottom list -->
    <template v-for="member in bottomList">
      <team-result-item
        :key="member.rank"
        :result="member"
        :screen="screenWidth"
      />
    </template>
    <!-- load more -->
    <template v-if="showLoadMoreButton">
      <div class="load-button">
        <mem-button
          :btnType="'tertiary-dark'"
          :loading="membersLoading"
          @click="loadMembers"
          >{{ $t("load_more") }}</mem-button
        >
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { head, last, get, isEmpty, filter, map } from "lodash";
export default {
  data: () => ({
    screenWidth: 0,
    isLoading: false,
    membersLoading: false,
  }),
  props: ["resultId"],
  computed: {
    ...mapGetters(["resultLeaderboard", "userData"]),
    bottomList() {
      let bottomIds = map(this.resultLeaderboard.listBottom, "id");
      let duplicates = filter(this.resultLeaderboard.listTop, (member) => {
        return bottomIds.indexOf(member.id) >= 0;
      });
      let duplicatesIds = map(duplicates, "id");
      return filter(this.resultLeaderboard.listBottom, (member) => {
        return duplicatesIds.indexOf(member.id) === -1;
      });
    },
    currentUserInTop() {
      return !isEmpty(
        filter(this.resultLeaderboard.listTop, { id: this.userData.id })
      );
    },
    listTopLastMemberRank() {
      return get(last(this.resultLeaderboard.listTop), "rank");
    },
    listBottomFirstMemberRank() {
      return get(head(this.resultLeaderboard.listBottom), "rank");
    },
    listBottomLastMemberRank() {
      return get(last(this.resultLeaderboard.listBottom), "rank");
    },
    showHiddenMembersRow() {
      return (
        this.resultLeaderboard.listBottom.length !== 0 &&
        this.listTopLastMemberRank + 1 < this.listBottomFirstMemberRank
      );
    },
    hiddenMembersCount() {
      return this.listBottomFirstMemberRank - this.listTopLastMemberRank - 1;
    },
    showLoadMoreButton() {
      return (
        this.listBottomLastMemberRank !== this.resultLeaderboard.total &&
        this.resultLeaderboard.listTop.length !== this.resultLeaderboard.total
      );
    },
  },
  components: {
    TeamResultItem: () => import("./TeamResultItem.vue"),
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  methods: {
    ...mapActions(["getResultLeaderboard", "clearResultLeaderboard"]),
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    async showHiddenMembers() {
      let queryLimit;
      if (this.hiddenMembersCount > 15) queryLimit = 15;
      else queryLimit = this.hiddenMembersCount;

      console.log(queryLimit, "LIMIT");

      await this.getResultLeaderboard({
        resultId: this.resultId,
        queryType: "loadHidden",
        queryLimit: queryLimit,
      });
    },
    async loadMembers() {
      this.membersLoading = true;

      let queryOffset = this.listBottomLastMemberRank + 1;
      if (this.currentUserInTop) {
        await this.getResultLeaderboard({
          resultId: this.resultId,
          queryType: "loadHidden",
          queryLimit: 15,
        });
      } else {
        await this.getResultLeaderboard({
          resultId: this.resultId,
          queryType: "loadMore",
          queryOffset: queryOffset,
        });
      }

      this.membersLoading = false;
    },
  },
  async mounted() {
    try {
      await this.getResultLeaderboard({ resultId: this.resultId });
      // console.log(this.listTopLastMemberRank);
      // console.log(this.listBottomFirstMemberRank);
      // console.log(this.listBottomLastMemberRank);
      // console.log(this.resultLeaderboard);
    } catch (err) {
      console.log("Get result leaderboard error", err);
    }
  },
  created() {
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateScreenWidth);
    this.clearResultLeaderboard();
  },
};
</script>
<style lang="scss" scoped>
.team-table {
  .table-header {
    display: grid;
    grid-template-columns: 1fr 90px 110px 104px;
    padding: 6px 45px 21px 20px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: end;
    color: #9d9d9d;
    @media screen and (max-width: 899px) {
      display: none;
    }
    .selected-column {
      position: relative;
    }
    .selected-column::before {
      position: absolute;
      bottom: -21px;
      right: 0;
      content: " ";
      height: 3px;
      width: 50px;
      background-color: #ffffff;
    }
  }
  .hidden-members {
    height: 70px;
    background: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include cursorPointer;
    .more-icon {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 58px;
    }
    .members-count {
      padding-left: 84px;
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      text-transform: uppercase;
      color: #808080;
    }
  }
  .load-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 90px;
  }
}
</style>